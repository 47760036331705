import React, { lazy } from "react";
import { useRoutes } from "react-router-dom";
import ROUTES from "../configs/_routes";
import RootLayout from "../layouts/_root"; 

 const Landing = lazy(() => import('../pages/_landing')); 
const PrivacyPolicy = lazy(() => import('../pages/_privacyPolicy')); 
const TermAndCondition = lazy(() => import('../pages/_termAndCondition')); 
const About = lazy(() => import('../pages/_about')); 
const Download = lazy(() => import('../pages/_download')); 
const AccountDelete = lazy(() => import('../pages/_accountDeleteRequest')); 
 
// For Unknow Routes
const Error404 = lazy(() => import('../pages/Error404'));

const AppRoutes = () => {

      const routes = useRoutes([
            {
                  path: ROUTES.LANDING,
                  element: <RootLayout />,
                  children: [
                         {
                              path: ROUTES.LANDING,
                              element: <Landing />
                        },
                         {
                              path: ROUTES.PRIVACY_POLICY,
                              element: <PrivacyPolicy />
                        },
                        {
                              path: ROUTES.TERM_AND_CONDITION,
                              element: <TermAndCondition />
                        }, 
                        {
                              path: ROUTES.ABOUT,
                              element: <About />
                        }, 
                        {
                              path: ROUTES.DOWNLOAD,
                              element: <Download />
                        }, 
                        {
                              path: ROUTES.ACCOUNT_DELETE,
                              element: <AccountDelete />
                        }, 
                        // 404 Not Found
                        {
                              path: "*",
                              element: <Error404 />
                        }
      
                  ]

            },  
      ])

      return routes;
}

export default AppRoutes; 