import React from 'react';
import { useThemeSetting } from '../../features'
import LogoBrown from '../../assets/images/BannerLogo_en_brown.png'
import LogoWhite from '../../assets/images/BannerLogo_en_white.png'
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../configs/_routes';

const LogoWithMode = ({height}) => {
    let navigate = useNavigate();
    const { mode } = useThemeSetting();
    return (
        mode === 'dark'
            ? <img src={LogoWhite} alt="banner-logo-white" style={{ height: height, cursor: 'pointer' }} onClick={() => navigate(ROUTES.LANDING)} />
            : <img src={LogoBrown} alt="banner-logo-brown" style={{ height: height, cursor: 'pointer' }} onClick={() => navigate(ROUTES.LANDING)} />
    )
}

export default LogoWithMode; 