import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { TYThemeProvider } from './providers';
import { GlobalLoading } from './components';
import AppRoutes from './routes';


function App() {
  return (
    <Suspense fallback={<GlobalLoading />}>
      <TYThemeProvider>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </TYThemeProvider>
    </Suspense>
  );
}

export default App;