import React from 'react';
import { AppBar, Box, Button, Toolbar } from '@mui/material';
import { Stack } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../configs/_routes';
import ChangeThemeButton from '../components/Buttons/ChangeThemeButton';
import { useLayoutSetting } from '../features'; 
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LogoWithMode from '../components/Logo/LogoWithMode';
import InfoIcon from '@mui/icons-material/Info';
import PolicyIcon from '@mui/icons-material/Policy';
import DescriptionIcon from '@mui/icons-material/Description';
import DownloadIcon from '@mui/icons-material/Download';

const TopNavigation = () => {
      const { isMobile } = useLayoutSetting();
      return (
            <AppBar position={'sticky'} color="default" elevation={0} sx={{ display: 'flex', pl: '12px', pr: '12px', bgcolor: 'background.default' }}>
                  <Toolbar>
                        <LogoWithMode height={30} />
                        {
                              isMobile
                                    ?
                                    <>
                                          <Box sx={{ flexGrow: 1 }} />
                                          <Stack direction={'row'} spacing={2} sx={{ alignItems: 'center' }} >
                                                <ChangeThemeButton />
                                                <PopupMenu />
                                          </Stack>
                                    </>
                                    :
                                    <>
                                          <ActionsMenu />
                                          <Box sx={{ flexGrow: 1 }} />
                                          <Stack direction={'row'} spacing={2} sx={{ alignItems: 'center' }} >
                                                <ChangeThemeButton />
                                          </Stack>
                                    </>
                        }
                  </Toolbar>
            </AppBar>
      )
};

function ActionsMenu() {
      let navigate = useNavigate();

      const handleOnClick = (route) => {
            navigate(route)
      };
      return (
            <Box sx={{ flexGrow: 1, ml: 8, display: { xs: 'flex', md: 'flex' } }}>
                  <Stack direction={'row'} spacing={2} sx={{ alignItems: 'center' }} >
                        <Button key={'terms-of-use'} sx={{ my: 2, display: 'block' }} onClick={() => handleOnClick(ROUTES.TERM_AND_CONDITION)}>
                              Terms of Use
                        </Button>
                        <Button key={'privacy-policy'} sx={{ my: 2, display: 'block' }} onClick={() => handleOnClick(ROUTES.PRIVACY_POLICY)} >
                              Privacy Policy
                        </Button>
                        <Button key={'about'} sx={{ my: 2, display: 'block' }} onClick={() => handleOnClick(ROUTES.ABOUT)}>
                              About
                        </Button>
                        <Button key={'download'} sx={{ my: 2, display: 'block' }} onClick={() => handleOnClick(ROUTES.DOWNLOAD)}>
                              Download
                        </Button>
                  </Stack>
            </Box>
      )
}

function PopupMenu() {
       let navigate = useNavigate();
      const [anchorEl, setAnchorEl] = React.useState(null);
      const open = Boolean(anchorEl);

      const handleClick = (event, route) => {
            setAnchorEl(event.currentTarget);
            route && navigate(route)
      };
      const handleClose = () => {
            setAnchorEl(null);
      };
      return (
            <React.Fragment>
                  <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                        <Tooltip title="Menu">
                              <IconButton
                                    onClick={handleClick}
                                    size="small"
                                    sx={{ ml: 2 }}
                                    aria-controls={open ? 'account-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                              >
                                    <MoreVertIcon />
                              </IconButton>
                        </Tooltip>
                  </Box>
                  <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                              elevation: 0,
                              sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                          width: 32,
                                          height: 32,
                                          ml: -0.5,
                                          mr: 1,
                                    },
                                    '&::before': {
                                          content: '""',
                                          display: 'block',
                                          position: 'absolute',
                                          top: 0,
                                          right: 14,
                                          width: 10,
                                          height: 10,
                                          bgcolor: 'background.paper',
                                          transform: 'translateY(-50%) rotate(45deg)',
                                          zIndex: 0,
                                    },
                              },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                  >
                        <MenuItem onClick={(e) => handleClick(e, ROUTES.DOWNLOAD)}>
                              <ListItemIcon>
                                    <DownloadIcon />
                              </ListItemIcon>
                              Download
                        </MenuItem>

                        <Divider />
                        <MenuItem onClick={(e) => handleClick(e, ROUTES.TERM_AND_CONDITION)}>
                              <ListItemIcon>
                                    <DescriptionIcon />
                              </ListItemIcon>
                              Term & Condition
                        </MenuItem>
                        <MenuItem onClick={(e) => handleClick(e, ROUTES.PRIVACY_POLICY)}>
                              <ListItemIcon>
                                    <PolicyIcon />
                              </ListItemIcon>
                              Privacy Policy
                        </MenuItem>
                        <MenuItem onClick={(e) => handleClick(e, ROUTES.ABOUT)}>
                              <ListItemIcon>
                                    <InfoIcon />
                              </ListItemIcon>
                              About
                        </MenuItem>
                  </Menu>
            </React.Fragment>
      );
}

export default TopNavigation;