import React from 'react';
import { Box } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery';
import { TopNavigation } from '../navigation';
import { useLayoutSetting } from '../features';
import { Outlet } from 'react-router-dom';

const root_layout =
{
      display: 'flex',
      flexDirection: 'column',
      margin: 0,
      flexGrow: 1,
      height: '100vh',
      transition: '0.5s',
      backgroundColor: '#F6F7F9',
      overflow: 'hidden',
      // overflowY: 'hidden',
}

const main_layout = {
      display: 'flex',
      flexDirection: 'column',
      bgcolor: 'background.default',
      height: '100%'
}
const content_area = {
      bgcolor: 'background.default',
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 'calc(10px + 2vmin)',
      color: 'text.primary',
      overflow: 'auto',
};

const RootLayout = () => {
      const { setAsMobileSize } = useLayoutSetting();

      const matches = useMediaQuery('(max-width:600px)');


      React.useEffect(() => {
            setAsMobileSize(matches);
            // eslint-disable-next-line
      }, [matches])



      return (
            <Box id={'root_main_layout'} sx={root_layout}>

                  {/* Content */}
                  <Box id='root_right_layout' sx={main_layout}>
                        {/* Top Navigation */}
                        <TopNavigation />

                        {/* Content */}
                        <Box id='root_layout_content_session' sx={content_area}>
                              <Outlet />
                        </Box>
                  </Box>
            </Box >
      )
}


export default RootLayout;
