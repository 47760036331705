import { configureStore } from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage'
import { combineReducers } from "redux";
import { persistReducer, createMigrate } from 'redux-persist'
//import hardSet from 'redux-persist/lib/stateReconciler/hardSet'

import {
      layoutSettingReducer,
      themeSettingReducer,
} from './features';

const migrations = {
      0: (state) => {
            // migration clear out device state
            console.log('Previous State:', ...state)
            return {
                  ...state,
                  device: undefined
            }
      },
      1: (state) => {
            // migration to keep only device state
            console.log("Migrated State:", state)
            return {
                  device: state.device
            }
      }
}

const persistConfig = {
      key: 'vshop',
      storage,
      version: 3,
      migrate: createMigrate(migrations, { debug: true }),
      // stateReconciler: hardSet,
      // whitelist: ['user', 'theme', 'layout', 'breadCrumb', 'meta_data'] // only navigation will be persisted
};

const reducers = combineReducers({
      theme: themeSettingReducer,
      layout: layoutSettingReducer,
});

const rootReducer = (state, action) => {
      return reducers(state, action);
}

const persistedReducer = persistReducer(persistConfig, rootReducer);
// localStorage.clear();

const store = configureStore({
      reducer: persistedReducer,
      middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                  serializableCheck: false,
            }),
});
export default store